import { FormEvent, useState } from 'react';
import { auth } from '../firebase-config';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import SignInFields from '../components/SignInFields/SignInFields';


function SignInPage() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const signInWithEmail = async (e: FormEvent) => {
        e.preventDefault();

        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate("/admin");
        } catch (error: any) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
        }
    }

    return (
        <div id="sign-in-container" className='animate__animated animate__fadeInUp'>
            <h1>Sign in</h1>
            <SignInFields email={email} setEmail={setEmail} password={password} setPassword={setPassword} signInWithEmail={signInWithEmail} />
        </div>
    )
}

export default SignInPage;
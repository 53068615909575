import { Navigate } from 'react-router-dom'


export type ProtectedRouteProps = {
    isAuthenticated: boolean;
    authenticationPath: string;
    element: JSX.Element;
};
  
function ProtectedRoute({isAuthenticated, authenticationPath, element}: ProtectedRouteProps) {
    console.log(isAuthenticated);
    return isAuthenticated ? element : <Navigate to={{ pathname: authenticationPath }} />;
};

export default ProtectedRoute;
import { FormEvent } from "react";

import './SignInFields.css';


interface SignInFieldsProps {
    email: string;
    setEmail: (email: string) => void;
    password: string;
    setPassword: (password: string) => void;
    signInWithEmail: (e: FormEvent) => void;
}

function SignInFields({email, setEmail, password, setPassword, signInWithEmail}: SignInFieldsProps) {
  return (
    <form id="sign-in-form" autoComplete='on' onSubmit={signInWithEmail}>
        <input type="email" value={email} placeholder='Email' onChange={e => setEmail(e.target.value)} required />
        <input type="password" value={password} placeholder='Password' onChange={e => setPassword(e.target.value)} required />
        <input type="submit" value="Sign in" />
    </form>
  );
}

export default SignInFields;
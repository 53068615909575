import { useState, useEffect } from 'react';
import { IGasquenEvent } from '../components/GasquenEvent/GasquenEvent';
import ICalParser from 'ical-js-parser';


function getDateObject(timestamp: string) {
    const year = Number(timestamp.slice(0, 4));
    const month = Number(timestamp.slice(4, 6));
    const day = Number(timestamp.slice(6, 8));
    const hour = Number(timestamp.slice(9, 11));
    const minute = Number(timestamp.slice(11, 13));

    return new Date(year, month - 1, day, hour, minute);
}

function formatEvents(events: any) {
    return events.map((event: any) => {
        const formattedEvent = {
            id: Number(event.uid.slice(event.uid.indexOf(".") + 1)),
            title: event.summary,
            organizer: event.organizer["CN"],
            start: getDateObject(event.dtstart.value),
            end: getDateObject(event.dtend.value),            
        }

        return formattedEvent as IGasquenEvent;
    });
}

export function useGasquenCalendar() {
    const [calendar, setCalendar] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch("https://admin.gasquen.se/api/calendar.ics");
            const data = await response.text();

            const jsonData = ICalParser.toJSON(data);
            jsonData.events = formatEvents(jsonData.events);
            
            setCalendar(jsonData);
            setLoading(false);
        }

        fetchData().catch(console.error);
    }, [])

    return [calendar, loading]
}
import { usePersonState } from "../../helpers";
import { MagnifyingGlass } from "react-loader-spinner";


function SvenStatus({nextEvent}: any) {
    const [svenState, svenStateLoading] = usePersonState({name: 'Sven'});

    const isToday = (date: Date) => {
        const today = new Date();
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    }

    if (svenStateLoading) {
        return <MagnifyingGlass glassColor='#0e0d0e' color='#ffffff' />
    }

    const getStatus = () => {
        if (!isToday(nextEvent.start) && !isToday(nextEvent.end)) {
            return "Det är ju inte ens gasque idag..."
        }
        
        return svenState?.at_gasquen ? "JA! Kom och träffa Sven!" : "Snart! Håll utkik i kön!"
    }

    return (
        <h2 className="sven-status">
            { getStatus() }
        </h2>
    );
}

export default SvenStatus;
import SvenStatus from '../components/SvenStatus/SvenStatus';
import { useGasquenCalendar } from '../helpers';
import GasquenEventList from '../components/GasquenEventList/GasquenEventList';

import { MagnifyingGlass, TailSpin } from 'react-loader-spinner';
import Typewriter from 'typewriter-effect';


function HomePage() {
    const [calendar, calendarLoading] = useGasquenCalendar();
    
    const getNextEvent = (calendar: any) => {
        const now = new Date();
        const nextEvent = calendar.events.find((event: any) => event.end > now);
        return nextEvent;
    }

    return (
        <>
        
        <main className='animate__animated animate__fadeInUp'>
            <h1 id="main-question">Är Sven på Gasquen?</h1>
            { calendarLoading ? <MagnifyingGlass glassColor='#0e0d0e' color='#ffffff' /> : <SvenStatus nextEvent={getNextEvent(calendar)} /> }
        </main>
        
        <div id="next-event-container" className='animate__animated animate__fadeInUp'>
            <h3>Nästa event:</h3>
            { calendarLoading ? <TailSpin color="#ffffff" /> : <GasquenEventList events={calendar.events} eventCount={1} /> }
        </div>
        
        </>
    );
}

export default HomePage;
import { useDebugValue } from 'react';

import { db } from '../firebase-config';
import { doc, DocumentSnapshot, FirestoreError } from "firebase/firestore";
import { useDocumentData } from 'react-firebase-hooks/firestore';


interface PersonState {
    at_gasquen: boolean;
}

type PersonStateQuery = [
    data: PersonState | undefined, 
    dataLoading: boolean, 
    dataError: FirestoreError | undefined, 
    dataSnapshot: DocumentSnapshot | undefined
]

export function usePersonState ({name}: {name: string}): PersonStateQuery {
    const stateRef = doc(db, 'state', name);
    const [data, dataLoading, dataError, dataSnapshot] = useDocumentData(stateRef);

    useDebugValue(dataLoading ? 'Loading...' : data);

    return [data as PersonState, dataLoading, dataError, dataSnapshot];
}
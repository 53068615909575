import { usePersonState } from '../helpers';
import { Link } from 'react-router-dom';

import AdminDashboard from '../components/AdminDashboard/AdminDashboard';
import { TailSpin } from  'react-loader-spinner'

import './AdminPage.css';


function AdminPage() {    
    const [data, dataLoading] = usePersonState({name: "Sven"});
    const atGasquen = data?.at_gasquen;

    if (dataLoading) {
        return <TailSpin color="#ffffff" />
    }

    return (
        <>
        <div className="filler-vertical center-vertical">
            <Link to={"/"}>
                <span className="material-symbols-outlined arrow animate__animated animate__fadeInUp">arrow_back</span>
            </Link>
        </div>

        <main id="main-admin" className='animate__animated animate__fadeInUp'>

            <h1>Current status: {atGasquen ? "At Gasquen" : "Not at Gasquen"}</h1>

            <AdminDashboard />
        </main>

        <div className="filler-vertical"></div>
        </>
    )
}

export default AdminPage;
import "./GasquenEvent.css"


export interface IGasquenEvent {
    id: number,
    title: string,
    organizer: string,
    start: Date,
    end: Date,
}

export function GasquenEvent({event}: {event: IGasquenEvent}) {
    const formatTime = (date: Date) => {
        return date.toLocaleTimeString("sv-SE", {timeZone: "Europe/Stockholm", timeStyle: "short"});
    }

    const formatDate = (date: Date) => {
        return date.toLocaleDateString("sv-SE", {timeZone: "Europe/Stockholm", month: "numeric", day: "numeric", weekday: "long"});
    }

    return (
        <a className="gasquen-event" href="https://gasquen.se/calendar">
            <h4>{event.organizer}</h4>
            <p>{event.title}</p>
            <p>{formatDate(event.start)}</p>
            <p>{formatTime(event.start)} - {formatTime(event.end)}</p>
            <span className="material-symbols-outlined">arrow_forward</span>
        </a>
    )
}

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyA-SmHaroOW_ZD8cwzGc4VN5uoTCX4LNZU",
    authDomain: "svenpagasquen.firebaseapp.com",
    projectId: "svenpagasquen",
    storageBucket: "svenpagasquen.appspot.com",
    messagingSenderId: "737913411688",
    appId: "1:737913411688:web:98c2d9672d16a55fbcc3dd",
    measurementId: "G-JNZV7M2Z44"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export default app;

import "./Footer.css";


function Footer() {
  return (
    <footer className='animate__animated animate__fadeInUp'>
      {
      /*
        <div id="quote-wrapper">
          <p>Visa citat från Sven/Intressant fakta:</p>
          <Typewriter
            options={{
                    strings: [
                'Jag är på Gasquen',
                'Jag är på Gasquen',
                'Jag är på Gasquen',
                'Jag är på Gasquen',
              ],
              autoStart: true,
              loop: true,
            }}
          />
      */
      }

      <p id="copyright">© 2022 Sven på Gasquen</p>
    </footer>
  );
}

export default Footer;
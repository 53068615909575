import { auth, db } from "../../firebase-config";
import { doc, updateDoc } from "firebase/firestore";

import "./AdminDashboard.css";


function AdminDashboard() {
    const svenStateRef = doc(db, "state", "Sven");

    return (
        <section id="admin-buttons">
            <div id="admin-state-buttons">
                <button className={"admin-button"} onClick={async () => {
                    await updateDoc(svenStateRef, { at_gasquen: true });
                }}>Check In</button>
                
                <button className={"admin-button"} onClick={async () => {
                    await updateDoc(svenStateRef, { at_gasquen: false });
                }}>Check Out</button>
            </div>
            
            <button id="signOut-btn" onClick={() => auth.signOut()}>Sign out</button>
        </section>
    )
}


export default AdminDashboard;
import './App.css';
import "animate.css"

import { auth } from './firebase-config';
import { useAuthState } from 'react-firebase-hooks/auth';

import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';

import HomePage from './pages/HomePage';
import AdminPage from './pages/AdminPage';
import SignInPage from './pages/SignInPage';

import Footer from './components/Footer/Footer';
import { TailSpin } from 'react-loader-spinner';


function App() {
  const [user, userLoading] = useAuthState(auth);

  if (userLoading)
    return <div className="App"><TailSpin color="#ffffff" /></div>

  return (
    <div className="App">
      { user && <nav className="user-info animate__animated animate__fadeInUp"><Link to={"/admin"}><h2>Admin</h2></Link></nav> }

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/admin" element={<ProtectedRoute isAuthenticated={user != null} authenticationPath="/signin" element={<AdminPage />}/>} />
        <Route path="/signin" element={<SignInPage />} />
        <Route path="*" element={<Navigate to="/"/>} />
      </Routes>

      <Footer />
    </div>
  )
}

export default App;
import { GasquenEvent } from "../GasquenEvent";
import { IGasquenEvent } from '../GasquenEvent/GasquenEvent';

import './GasquenEventList.css';


function GasquenEventList({events, eventCount}: {events: IGasquenEvent[], eventCount: number}) {
    return (
        <div className="gasquen-events">
            { events.slice(0, eventCount).map((event: IGasquenEvent, index: number) => <GasquenEvent event={event} key={index} />) }
        </div>
    );
}

export default GasquenEventList;